<template>
  <el-container class="perms">
    <el-aside width="300px">
      <el-card header="组织部门管理" class="box-card">
        <el-input
          placeholder="请输入搜索内容"
          clearable
          v-model="filterText"
          suffix-icon="el-icon-search"
          size="small"
        ></el-input>
        <el-tree 
          v-loading="dataListLoading"
          :data="dataList"
          :props="dataListTreeProps"
          node-key="id"
          ref="dataListTree"
          @current-change="dataListTreeCurrentChangeHandle"
          :default-expanded-keys="treeExpandData"
          :filter-node-method="filterNode"
          :accordion="true"
          :highlight-current="true"
          current-node-key="0"
          :expand-on-click-node="false" 
        >
          <span slot-scope="{ node, data }">
            <icon-svg :name="orgDepIcon(data.type)" style="font-size:14px;"/>
            <span >{{ data.name }}</span>
          </span> 
        </el-tree>
      </el-card>
    </el-aside>
    <el-main id="reder-item-main" class="item-main">
      <list ref="list" @getTreeList="getTreeList" :parentName="parentName"></list> 
    </el-main>
  </el-container>
</template>

<script>
import {getTree, keyworTree, orgTreeByPid, treeByPids} from '@/api/sys/sysorg.js'
import list from './list'
export default {
  data() {
    return {
      dataForm: {
        key: '',
        projectName:""
      },
      id: '',
      type: '',
      parentName: "",
      dataListLoading: false,
      dataPermissionId: '',
      dataList: [],
      treeExpandData: [],
      dataListTreeProps: {
        label: "name",
        children: "children"
      },
      projectId:"",
      filterText:"",
      checkTreeNode : { 
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.dataListTree.filter(val);
    }
  },
  components: {
    list
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    orgDepIcon(type) {
      if(type === 'org') {
        return 'icon-Exportservices';
      } else if(type === 'dept') {
        return 'icon-connections';
      }
    },
    getTreeList(){
      this.getDataList();
    },
    // 菜单树选中
    dataListTreeCurrentChangeHandle(data, node) {
      this.id = data.id
      this.type = data.type
      this.checkTreeNode = data;
      this.$refs.list.init(data) 
      this.menuSelectShow = false;
    },
    //tree中的filter-node-method为检索提供服务
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    }, 
    expandOrgNode(treeNode) {
      if(treeNode['type'] === 'org') { 
        //默认展开“组织角色树”
        this.treeExpandData.push(treeNode['id']);

        if(treeNode.children) {
          treeNode.children.forEach(childNode => {
            this.expandOrgNode(childNode);
          })
        }
      }
    },
    // 获取数据列表
    getDataList() {
      let _this = this;
      this.dataListLoading = true
      treeByPids(this.$store.state.user.orgId).then(({data}) => {
        console.log(this.$store.state.user.orgId,1111111111111)
        if (data && data.code === 0) {
          _this.dataList = data.data
          _this.dataList.forEach(treeNode => {
            _this.expandOrgNode(treeNode); 
          }); 
          if(_this.checkTreeNode.id) {
            _this.dataListTreeCurrentChangeHandle(_this.checkTreeNode);
          } else if(this.dataList[0]) {
            _this.dataListTreeCurrentChangeHandle(_this.dataList[0]);
          }
          if(_this.filterText) {
            _this.$nextTick(() => {
              _this.$refs.dataListTree.filter(_this.filterText);
            }); 
          }
        } else {
          _this.dataList = []
          _this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.dataListLoading = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .el-aside {
    height: calc(100vh - 108px);
    overflow: auto;
  }

  .item-main {
    width: calc(100% - 600px);
  }
  /*只是树形结构底下会出现滚动条，不是aside页面的*/
  .el-tree {
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: white;
  }

  .el-tree>.el-tree-node {
    display: inline-block;
    min-width: 100%;
  }

</style>